<script setup>
import router from "@/router";
import BaseButton from "@/components/base/BaseButton.vue";
import {useCartStore} from "@/store/CartStore";
import {storeToRefs} from "pinia";

const { total_price } = storeToRefs(useCartStore());

const handleNavigateToCart = () => {
  router.push({
    path: "/cart",
  });
};
</script>

<template>
  <div class="d-flex flex-row align-center justify-end ga-4">
    <v-icon
      icon="mdi-cart-outline"
      size="large"
    />
    <v-sheet class="semibold">
      {{ total_price }}&nbsp;₽
    </v-sheet>
    <base-button
      :max-width="192"
      text="ОФОРМИТЬ ЗАКАЗ"
      @click="handleNavigateToCart"
    />
  </div>
</template>
