  <script setup>
  import {storeToRefs} from "pinia";
  import {useMenuStore} from "@/store/MenuStore";
  import {computed, ref, watch} from "vue";
  import RecommendedIngredient from "@/components/Menu/components/RecommendedIngredient.vue";
  import BaseButton from "@/components/base/BaseButton.vue";

  const showAll = ref(false);
  const { ingredientsModalState } = storeToRefs(useMenuStore());
  const menuStore = useMenuStore();
  const selectedModifiers = ref([]);
  const updateSelectedModifier = (modifier, selectedId) => {
    const existingModifier = selectedModifiers.value.find(mod => mod.modifier.id === modifier.id);
    if (existingModifier) {
      existingModifier.selectedId = selectedId;
    } else {
      selectedModifiers.value.push({ modifier: modifier, selectedId });
    }
  };
  const updatedModifiers = computed(() => {
    return product.value.modifiers.map((modifier) => {
      const existingSelection = selectedModifiers.value.find(
          (mod) => mod.modifier.id === modifier.id
      );
      return {
        ...modifier,
        selected: existingSelection ? existingSelection.selectedId : null,
      };
    });
  });
  const product = computed(() => ingredientsModalState.value?.product);
  const selectedIngredients = ref([]);

  const recommendedIngredients = computed(() => ingredientsModalState.value?.product.recommendedIngredients)

  const toggleSelection = (id) => {
    if (selectedIngredients.value.includes(id)) {
      selectedIngredients.value = selectedIngredients.value.filter(item => item !== id);
    } else {
      selectedIngredients.value.push(id);
    }
  };

  const displayedIngredients = computed(() => {
    if (!product.value || !product.value.ingredients) return [];
    return product.value.ingredients.filter(item1 =>
        !recommendedIngredients.value.some(item2 => item1.id === item2.id)
    );
  });

  const toggleShowAll = () => {
    showAll.value = !showAll.value;
  };

  const handleAddProductToCart = () => {
    const modifiers = selectedModifiers.value.map((modifier) => modifier.selectedId)
    menuStore.addProductToCart({...product.value, quantity: 1}, modifiers, updatedIngredientsFoCreation.value);
    menuStore.closeIngredientsModal();
  };

  watch(
      () => ingredientsModalState.value.isOpen,
      (newVal) => {
        if (!newVal) {
          selectedIngredients.value = []; // Сбрасываем при закрытии модального окна
        }
      }
  );

  watch(() => ingredientsModalState.value.isOpen, () => {
    if (!ingredientsModalState.value.isOpen) {
      selectedModifiers.value = [];
      selectedIngredients.value = [];
    }
  })

  const updatedIngredientsFoCreation = computed(() => {
    return selectedIngredients.value.map((ingr) =>  {
      return {
        id: ingr,
        quantity: 1,
      }
    })
  })

  const handleCLickCross = () => {
    menuStore.closeIngredientsModal();
  }

  </script>

<template>
  <v-dialog
    v-model="ingredientsModalState.isOpen"
    width="100%"
    max-width="1000"
  >
    <v-card
      width="full"
      flat
      rounded="xl"
    >
      <template #append>
        <v-sheet>
          <v-icon
            color="gray"
            class="cursor-pointer text-h4"
            icon="mdi-close"
            size="large"
            @click="handleCLickCross"
          />
        </v-sheet>
      </template>
      <v-row
        no-gutters
        class="py-8 px-0"
      >
        <v-col
          cols="12"
          sm="6"
          class="px-4"
        >
          <v-img
            width="100%"
            height="auto"
            min-height="150"
            :src="product.image_full_url"
            cover
            rounded
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-4"
        >
          <v-card-title
            class="text-h5 px-0 no-ellipsis font-weight-bold text-uppercase mb-0 pb-0 text-pre-wrap"
          >
            {{ product.title }}
          </v-card-title>

          <v-card-subtitle
            class="mt-0 pa-0 mb-5 text-left text-caption text-black opacity-100"
          >
            {{ product.meta_weight }} г.
          </v-card-subtitle>

          <v-col v-if="updatedModifiers.length > 0">
            <v-row
              v-for="modifier in updatedModifiers"
              :key="modifier.id"
              class="mb-2"
            >
              <v-select
                v-if="modifier.items"
                v-model="modifier.selected"
                class="font-weight-light text-caption pa-0"
                tile
                flat
                rounded="2"
                hide-details
                :items="modifier.items"
                density="compact"
                :placeholder="modifier.title"
                variant="outlined"
                item-value="id"
                item-text="title"
                max-width="260"
                @update:model-value="(selectedId) => updateSelectedModifier(modifier, selectedId)"
              >
                <template #selection="{ item }">
                  <v-sheet class="pa-0 font-weight-light text-caption">
                    {{ item.title }}
                  </v-sheet>
                </template>
              </v-select>
            </v-row>
          </v-col>

          <v-card-text class="text-h6 custom-line-height px-0 py-0 mb-2 semibold">
            Так еще вкуснее
          </v-card-text>

          <v-sheet class="mb-4 d-flex flex-row ga-2">
            <recommended-ingredient
              v-for="ingredient in recommendedIngredients"
              :key="ingredient.id"
              :ingredient="ingredient"
              :selected="selectedIngredients.find((item) => item === ingredient.id) !== undefined"
              @click="toggleSelection(ingredient.id)"
            />
          </v-sheet>

          <v-sheet>
            <v-card-text
              class=" text-h6 pa-0 cursor-pointer mb-2"
              :class="{
                'text-customRed': !showAll,
                'text-grey-darken-1': showAll
              }"
              @click="toggleShowAll"
            >
              {{ showAll ? 'Скрыть список' : 'Показать полный список' }}
            </v-card-text>
            <v-sheet
              v-if="showAll"
              class="d-flex flex-wrap flex-row align-start"
            >
              <v-checkbox
                v-for="ingredient in displayedIngredients"
                :key="ingredient.id"
                class="pa-0 ma-0 mr-2"
                hide-details
                min-height="none"
                density="compact"
              >
                <template #input>
                  <div
                    class="custom-checkbox pa-0"
                    :class="{
                      'custom-checkbox-checked': selectedIngredients.includes(ingredient.id),
                      'custom-checkbox-disabled': false
                    }"
                    @click="toggleSelection(ingredient.id)"
                  >
                    <v-icon
                      v-if="selectedIngredients.includes(ingredient.id)"
                      color="white"
                    >
                      mdi-check
                    </v-icon>
                  </div>
                </template>
                <template #label>
                  <v-card-text class="custom-checkbox-label semibold pa-0 ma-0">
                    {{ ingredient.title }} <span class="font-weight-bold text-black">{{ ingredient.price }} ₽</span>
                  </v-card-text>
                </template>
              </v-checkbox>
            </v-sheet>
          </v-sheet>

          <v-card-text class="text-subtitle-2 custom-line-height px-0 py-0">
            {{ product.description }}
          </v-card-text>

          <v-card-text
            class="text-subtitle-2 custom-line-height-2 px-0 pb-2"
          >
            Ккал:&nbsp;{{ product.meta_calories }}, белки:&nbsp;{{
              product.meta_proteins
            }}&nbsp;г., жиры:&nbsp;{{ product.meta_fat }}&nbsp;г.,
            углеводы:&nbsp;{{ product.meta_carbohydrates }}&nbsp;г.
          </v-card-text>

          <v-sheet class="d-flex flex-row align-center ga-16 pt-4">
            <v-sheet
              class="text-h4 semibold custom-line-height px-0 py-0"
            >
              {{ product.price }}&nbsp;₽
            </v-sheet>
            <base-button
              max-width="160"
              text="В корзину"
              :uppercase="true"
              @click="handleAddProductToCart"
            >
              В КОРЗИНУ
            </base-button>
            <!--            <v-btn-->
            <!--              v-if="product"-->
            <!--              class="rounded-xl text-h6 font-weight-bold text-uppercase mb-0 pb-0"-->
            <!--              color="#e31d24"-->
            <!--              width="160"-->
            <!--              height="50"-->
            <!--              flat-->
            <!--              @click="handleAddProductToCart"-->
            <!--            >-->
            <!--              В корзину-->
            <!--            </v-btn>-->
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

  <style scoped>
  input {
    all: unset;
  }
  .custom-checkbox {
    all: unset;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid #000;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s, border 0.3s;
    margin-right: 6px;
  }

  .custom-checkbox-checked {
    background-color: #e31d24;
    border-color: #e31d24;
  }

  .custom-checkbox-disabled {
    background-color: #f5f5f5;
    border-color: #dcdcdc;
    cursor: not-allowed;
  }

  .custom-checkbox-label {
    margin-left: 8px;
    font-size: 14px;
  }

  .v-icon {
    font-size: 12px;
  }
  </style>
