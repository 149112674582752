<script setup>
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import {useAuthStore} from "@/store/AuthStore";
import {useDisplay} from "vuetify";

const menuStore = useMenuStore();
const authStore = useAuthStore();
const {cities, currentCity, isLoading} = storeToRefs(menuStore);

const handleSelectCity = async (newVal) => {
  await menuStore.setCurrentCity(newVal)
  if (authStore.isAuthenticated) {
    await authStore.editProfile({city_id: newVal.id})
  }
}
const { mobile } = useDisplay();


</script>

<template>
  <div class="d-flex flex-column header-contacts-container">
    <div class="d-flex flex-row align-items-center justify-center">
      <v-select
        v-model="currentCity"
        hide-details
        :flat="true"
        class="custom-select pa-0"
        :items="cities"
        bg-color="white"
        tile
        return-object
        :style="{fontSize: mobile ? '14px' : '16px', padding: 0} "
        density="compact"
        placeholder="Город"
        :loading="isLoading"
        menu-icon=""
        variant="plain"
        @update:model-value="handleSelectCity"
      >
        <template #prepend-inner>
          <v-icon
            class="custom-icon mr-1"
            size="x-small"
          >
            mdi-map-marker
          </v-icon>
        </template>

        <div
          v-for="item in cities"
          :key="item"
          :style="{fontSize: mobile ? '14px' : '16px'} "
        >
          {{ item.title }}
        </div>
      </v-select>
    </div>
  </div>
</template>

<style>
.header-contacts-container {
  min-width: 145px;
  .v-input__control {
    max-height: 32px;
  }
  .v-field {
    padding: 0;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
  .v-field__input {
    padding: 0;
  }
}
.title {
  font-size: 16px;
  line-height: 16px;
}
</style>
