<script setup>
import CartItemsList from "@/components/Cart/CartItemsList/CartItemsList.vue";
import CartHeader from "@/components/Cart/CartHeader/CartHeader.vue";
import CartDevices from "@/components/Cart/CartDevices/CartDevices.vue";
import CartCheck from "@/components/Cart/CartCheck/CartCheck.vue";
import CartAdditional from "@/components/Cart/CartAdditional/CartAdditional.vue";
import CartForm from "@/components/Cart/CartForm/CartForm.vue";
import {watch} from "vue";
import { useCartStore } from "@/store/CartStore";
import {storeToRefs} from "pinia";
import NotifyAboutIngredients from "@/components/modals/NotifyAboutIngredients.vue";

const cartStore = useCartStore();

const { order } = storeToRefs(cartStore);

watch(order.value, (newVal)=> {
  if (newVal !== null) {
    window.location.replace(order.value.payment_url);
  }
})
</script>

<template>
  <v-sheet
    rounded
    elevation="0"
    class="pa-0 d-flex align-start flex-column w-100"
  >
    <v-col cols="12">
      <v-row class="pa-0">
        <cart-header />
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="pa-0  px-sm-3"
        >
          <cart-items-list />
          <v-sheet class="pa-0 mt-4">
            <cart-additional />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pa-0 px-sm-3"
        >
          <v-row
            align-content="center"
            class="w-100 ma-0 gr-3 "
          >
            <v-col
              xs="12"
              sm="6"
              md="12"
              lg="6"
              cols="12"
              class="pa-0 px-sm-2"
            >
              <cart-devices />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="12"
              lg="6"
              xs="12"
              class="pa-0 px-sm-2"
            >
              <cart-check />
            </v-col>
          </v-row>
          <v-row>
            <cart-form />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <notify-about-ingredients />
  </v-sheet>
</template>
