<script setup>
import {computed, defineProps, onMounted, ref, watch} from "vue";
import CountToggler from "@/components/base/CountToggler/CountToggler.vue";
import { useCartStore } from "@/store/CartStore";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const cartStore = useCartStore();

const handleCLickPlus = () => {
  cartStore.cartItemUpdate(props.product.id, props.product.quantity + 1);
};

const handleCLickMinus = () => {
  if (props.product.quantity <= 1) {
    cartStore.removeFromCart(props.product.id);
    return;
  }
  cartStore.cartItemUpdate(props.product.id, props.product.quantity - 1);
};

const handleCLickCross = () => {
  cartStore.removeFromCart(props.product.id);
};
</script>

<template>
  <v-sheet
    rounded="5"
    class="w-100 pa-4 ga-4 elevation-1 rounded-xl position-relative"
  >
    <v-sheet class="d-flex flex-column justify-center flex-sm-row ga-4">
      <v-sheet
        width="175"
        height="170"
        class="d-flex justify-center align-center"
      >
        <v-img
          width="175"
          height="auto"
          :src="props.product.product.image_full_url"
          cover
          rounded
        />
      </v-sheet>
      <v-sheet class="flex-1-0-0 h-100">
        <v-card-title
          style="line-height: 1.2"
          class="text-subtitle-1 semibold pa-0 text-uppercase mb-4 text-pre-wrap pr-7"
        >
          {{ props.product.product.title }}
        </v-card-title>

        <v-sheet
          v-if="props.product.modifier_items.length > 0"
          class="pa-0 mb-2 pb-3"
        >
          <v-sheet
            v-for="modifier in props.product.modifier_items"
            :key="modifier.id"
            class="d-flex justify-space-between align-center flex-row"
          >
            <v-sheet class="text-grey-darken-4 text-subtitle-2">
              {{ modifier.title }}
            </v-sheet>
            <v-sheet class="text-grey-darken-4 text-subtitle-2">
              {{ modifier.price }} ₽
            </v-sheet>
          </v-sheet>
        </v-sheet>

        <v-sheet
          v-if="props.product.ingredients.extra.length > 0"
          class="pa-0 mb-2 pb-3"
        >
          <v-sheet
            v-for="ingredient in props.product.ingredients.extra"
            :key="ingredient.id"
            class="d-flex justify-space-between align-center flex-row"
          >
            <v-sheet class="text-grey-lighten-1 text-subtitle-2">
              {{ ingredient.ingredient.title }}
            </v-sheet>
            <v-sheet class="text-grey-lighten-1 text-subtitle-2">
              {{ ingredient.total_price }} ₽
            </v-sheet>
          </v-sheet>
        </v-sheet>

        <count-toggler
          :handle-click-minus="handleCLickMinus"
          :handle-click-plus="handleCLickPlus"
          :value="props.product.quantity"
          :loading="true"
        />
      </v-sheet>
    </v-sheet>
    <v-sheet
      class="d-flex position-absolute top-0 right-0 pt-4 pr-4"
    >
      <v-icon
        color="gray"
        class="text-grey-darken-1 cursor-pointer"
        icon="mdi-close"
        size="large"
        @click="handleCLickCross"
      />
    </v-sheet>
    <v-sheet
      class="d-flex flex-column position-absolute bottom-0 right-0 pb-4 pr-4 bg-transparent"
    >
      <v-sheet
        style="font-size: 15px;"
        class="semibold text-end"
      >
        {{ props.product.product.meta_weight }}г
      </v-sheet>
      <v-sheet
        style="font-size: 18px;"
        class="semibold font-weight-bold text-end"
      >
        {{ props.product.total_price }} ₽
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>
