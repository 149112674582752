<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import MenuListNav from "@/components/Header/MenuListNav/MenuListNav.vue";
import HeaderContacts from "@/components/Header/HeaderContacts/HeaderContacts.vue";
import HeaderHours from "@/components/Header/HeaderHours/HeaderHours.vue";
import HeaderSearch from "@/components/Header/HeaderSearch/HeaderSearch.vue";
import HeaderLocalization from "@/components/Header/HeaderLocalization/HeaderLocalization.vue";
import HeaderCart from "@/components/Header/HeaderCart/HeaderCart.vue";
import MobileMenuListNav from "@/components/Header/MenuListNav/MobileMenuListNav.vue";
import {useDisplay} from "vuetify";
import HeaderMobileCart from "@/components/Header/HeaderCart/HeaderMobileCart.vue";

const isVisible = ref(true);
const { mobile, smAndDown, xs } = useDisplay();

const handleScroll = () => {
  const currentScrollPosition = window.scrollY;
  isVisible.value = currentScrollPosition <= 0;
}


onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);

});

</script>

<template>
  <div
    class="w-100"
    :class="mobile ? 'pa-2' : 'pa-5'"
  >
    <v-row
      class="w-100 header-container pa-0 ma-0 align-center mx-auto ga-2"
      :class="mobile ? 'justify-start' : 'justify-space-between'"
      no-gutters
    >
      <v-col
        cols="auto"
      >
        <HeaderContacts />
      </v-col>

      <v-col
        v-if="!xs"
        cols="3"
        lg="2"
      >
        <header-search />
      </v-col>

      <v-col
        v-if="!mobile"
        cols="auto"
      >
        <header-localization />
      </v-col>

      <v-col
        v-if="isVisible || mobile"
        sm="2"
        cols="3"
        class="d-flex justify-center align-center"
      >
        <v-img
          :style="{maxWidth: smAndDown ? '200px' :''}"
          width="100%"
          height="auto"
          max-height="110"
          src="@/assets/Logo_Tom_Yam.svg"
        />
      </v-col>

      <v-col
        v-if="!isVisible && !mobile"
        cols="6"
      >
        <MenuListNav />
      </v-col>

      <v-col
        v-if="isVisible && !mobile"
        cols="2"
      >
        <HeaderHours />
      </v-col>

      <v-col
        v-if="!mobile"
        cols="2"
        class="d-flex align-center justify-end"
      >
        <HeaderCart />
      </v-col>

      <v-col
        v-if="mobile"
        cols="2"
        class="d-flex justify-end px-0 ml-auto"
        :class="mobile ? 'justify-center' :'justify-end'"
      >
        <MobileMenuListNav />
      </v-col>
    </v-row>
    <header-mobile-cart v-if="mobile" />
  </div>
</template>

<style scoped>
.container {
  z-index: 1000;
}
.header-container {
  max-width: 1400px;
}
</style>
