import {gql} from "@apollo/client/core";

export const PRODUCT_FIELDS = gql`
  fragment ProductFields on Product {
    id
    title
    short_description
    description
    price
    priority
    meta_weight
    meta_weight_unit
    meta_calories
    meta_proteins
    meta_fat
    meta_carbohydrates
    image_full_url
    image_small_url
    image_catalog_small_url
    recommendedIngredients {
      id
      title
      price
      type
      type_key
      image_url
      meta_weight
      meta_weight_unit
      priority
    }
    ingredients {
      id
      title
      price
      type
      type_key
      image_url
      meta_weight
      meta_weight_unit
      priority
    }
    modifiers {
      id
      title
      title_form
      items {
        id
        title
        price
      }
    }
    flags {
      id
      title
      image_url
      priority
    }
    active
  }
`

export const CART_ITEMS_FIELDS = gql`
  ${PRODUCT_FIELDS}
  fragment CartItemsFields on CartItem {
    id
    quantity
    scope
    product {
      ...ProductFields
    }
    modifier_items {
      id
      title
      price
    }
    ingredients {
      extra {
        ingredient {
          id
          title
          price
          type
          type_key
          image_url
          meta_weight
          meta_weight_unit
          priority
        }
        quantity
        total_price
      }
      part_remove {
        id
        title
        price
        type
        type_key
        image_url
        meta_weight
        meta_weight_unit
        priority
      }
    }
    total_price
  }
`;

export const ORDER_MODIFIER_ITEM_FIELDS = gql`
  fragment OrderModifierItemFields on OrderModifierItem {
    id
    title
    modifier_title
    price
  }
`;

export const ORDER_ITEM_EXTRA_INGREDIENT_FIELDS = gql`
  fragment OrderItemExtraIngredientFields on OrderItemExtraIngredient {
    id
    price
    title
    quantity
    total_price
  }
`;

export const ORDER_ITEM_PART_REMOVE_INGREDIENT_FIELDS = gql`
  fragment OrderItemPartRemoveIngredientFields on OrderItemPartRemoveIngredient {
    id
    title
  }
`;


export const ORDER_ITEM_INGREDIENTS_FIELDS = gql`
  ${ORDER_ITEM_EXTRA_INGREDIENT_FIELDS}
  ${ORDER_ITEM_PART_REMOVE_INGREDIENT_FIELDS}
  fragment OrderItemIngredientsFields on OrderItemIngredients {
    extra {
      ...OrderItemExtraIngredientFields
    }
    part_remove {
      ...OrderItemPartRemoveIngredientFields
    }
  }
`;


export const ORDER_PRODUCT_FIELDS = gql`
  fragment OrderProductFields on OrderProduct {
    id
    title
    price
    image_small_url
    meta_weight
    meta_weight_unit
  }
`;

export const ORDER_ITEM_FIELDS = gql`
  ${ORDER_PRODUCT_FIELDS}
  ${ORDER_MODIFIER_ITEM_FIELDS}
  ${ORDER_ITEM_INGREDIENTS_FIELDS}
  fragment OrderItemFields on OrderItem {
    id
    quantity
    total_price
    product {
      ...OrderProductFields
    }
    modifier_items {
      ...OrderModifierItemFields
    }
    ingredients {
      ...OrderItemIngredientsFields
    }
  }
`;


export const ORDER_FIELDS = gql`
  ${ORDER_ITEM_FIELDS}
  fragment OrderFields on Order {
    id
    order_number
    payment_url
    items {
      ...OrderItemFields
    }
    status
    status_title
    completion_time
    created_at
    delivery_price
    address_delivery
    total_price
  }
`;


