<script setup>

</script>

<template>
  <v-sheet
    class="position-sticky w-100 top-0 border-b bg-white pa-4 container d-flex justify-center align-center"
  >
    <v-sheet width="146">
      <router-link
        to="/"
        class="d-flex d-flex align-center justify-center text-grey-darken-3 text-decoration-none"
      >
        <v-img
          width="100%"
          height="auto"
          src="@/assets/Logo_Tom_Yam.svg"
        />
      </router-link>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.container {
  z-index: 1000;
}
</style>
