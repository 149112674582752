<script setup>

import FooterBlock from "@/components/Footer/FooterBlock.vue";
import {computed} from "vue";
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import FooterDeliveryRules from "@/components/Footer/FooterDeliveryRules.vue";
import FooterPayProccess from "@/components/Footer/FooterPayProccess.vue";
import {cleanLink, formatPhoneNumber} from "@/helpers";

const menuStore = useMenuStore();
const { currentCity } = storeToRefs(menuStore)

const addresses = computed(() => {
  return currentCity.value ? currentCity.value.addresses : [];
})

const contacts = computed(() => {
  return currentCity.value ? currentCity.value.contacts : [];
})

const email = computed(() => contacts.value.find((contact) => contact.title === 'email'))

const telegram = computed(() => contacts.value.find((contact) => contact.title === 'Telegram'))

const phone = computed(() => contacts.value.find((contact) => contact.title === 'phone'))

const vk = computed(() => contacts.value.find((contact) => contact.title === 'vk'))

</script>

<template>
  <v-container
    id="footer"
    fluid
    class="footer ma-0 text-white py-12"
  >
    <v-sheet
      max-width="1432"
      class="mx-auto bg-transparent"
    >
      <v-row>
        <v-col cols="5">
          <v-row>
            <v-col>
              <footer-block
                title="Партнерам"
              >
                <v-sheet class="ma-0 pa-0 d-flex flex-column bg-transparent text-decoration-underline text-subtitle-2 text-white">
                  <a
                    href="https://tomyumbar.com/franchise"
                    target="_blank"
                    class="text-white"
                  >
                    O франшизе
                  </a>
                  <a
                    href="https://tomyumbar.com/application"
                    target="_blank"
                    class="text-white"
                  >
                    Заявка на франшизу
                  </a>
                </v-sheet>
              </footer-block>
            </v-col>
            <v-col>
              <footer-block
                title="Режим доставки"
              >
                <v-sheet class="ma-0 pa-0 d-flex flex-column bg-transparent text-subtitle-2">
                  <div>
                    10:00-21:40 (ежедневно)
                  </div>
                </v-sheet>
              </footer-block>
            </v-col>
          </v-row>
          <v-row>
            <v-sheet
              class="bg-transparent text-center text-no-wrap text-subtitle-1 regular pr-10 pt-10 pl-4"
              style="line-height: 1.4"
            >
              ООО «РестоМир»
            </v-sheet>
          </v-row>
        </v-col>
        <v-col>
          <footer-block
            title="Наши контакты"
          >
            <v-sheet class="ma-0 pa-0 d-flex flex-column bg-transparent text-subtitle-2 text-white">
              <v-sheet
                v-for="address in addresses"
                :key="address.id"
                class="bg-transparent d-flex flex-column mb-4"
              >
                <a
                  v-if="phone"
                  class="text-white text-decoration-underline"
                  :href="phone.link"
                >
                  {{ formatPhoneNumber(cleanLink(phone.link)) }}
                </a>
                <div>
                  г.{{ currentCity.title }}, {{ address.title }}
                </div>
                <a
                  v-if="email"
                  :href="email.link"
                  class="text-white text-decoration-underline"
                >
                  {{ cleanLink(email.link) }}
                </a>
              </v-sheet>
            </v-sheet>
          </footer-block>
        </v-col>
        <v-col>
          <footer-block
            title="Мы в соц. сетях"
          >
            <v-sheet class="ma-0 pa-0 d-flex flex-row ga-3 mt-3 bg-transparent">
              <a
                v-if="telegram"
                :href="telegram.link"
                target="_blank"
              >
                <v-img
                  width="24px"
                  height="24px"
                  src="@/assets/telegram_footer.svg"
                />
              </a>
              <a
                v-if="vk"
                href="https://vk.com/tomyumbar"
                target="_blank"
              >
                <v-img
                  width="24px"
                  height="24px"
                  src="@/assets/vk_footer.svg"
                />
              </a>
            </v-sheet>
          </footer-block>
        </v-col>
      </v-row>
      <v-sheet class="bg-transparent d-flex align-center justify-center mt-5 ga-4">
        <footer-delivery-rules />
        <footer-pay-proccess />
        <a
          class="text-white text-decoration-underline"
          href="https://sochi.tomyumbar.ru/privacу.pdf"
        >Политика конфиденциальности</a>
      </v-sheet>
      <v-sheet class="bg-transparent d-flex align-center justify-center mt-5">
        ООО «РестоМир», ИНН: 2366043730
      </v-sheet>
      <v-sheet class="bg-transparent d-flex align-center justify-center mt-5">
        TomYumBar © {{ new Date().getFullYear() }}
      </v-sheet>
    </v-sheet>
  </v-container>
</template>

<style scoped>
.footer {
  background-color: #282828;
}
</style>
