import {gql} from "@apollo/client/core";
import {CART_ITEMS_FIELDS} from "@/api/fragments";

export const CART_CLEAR = gql`
  ${CART_ITEMS_FIELDS}
  mutation cartClear {
    cartClear {
      delivery_price
      total_price
      items {
        ...CartItemsFields
      }
    }
  }
`

export const CART_ITEM_DELETE = gql`
  ${CART_ITEMS_FIELDS}
  mutation cartItemDelete($id: ID!) {
    cartItemDelete(id: $id) {
      delivery_price
      total_price
      items {
        ...CartItemsFields
      }
    }
  }
`

export const CART_ITEM_UPDATE = gql`
  ${CART_ITEMS_FIELDS}
  mutation cartItemUpdate($id: ID!, $quantity: Int!) {
    cartItemUpdate(id: $id, quantity: $quantity) {
      delivery_price
      total_price
      items {
        ...CartItemsFields
      }
    }
  }
`

export const CART_ITEM_CREATE = gql`
  ${CART_ITEMS_FIELDS}
  mutation cartItemCreate(
    $product_id: ID!
    $quantity: Int!
    $modifier_items: [ID!]
    $extra_ingredients: [CartIngredient!]
    $part_remove_ingredients: [ID!]
  ) {
    cartItemCreate(
      product_id: $product_id
      quantity: $quantity
      modifier_items: $modifier_items
      extra_ingredients: $extra_ingredients
      part_remove_ingredients: $part_remove_ingredients
    ) {
      delivery_price
      total_price
      items {
        ...CartItemsFields
      }
    }
  }
`

export const CART_CALCULATE_DELIVERY = gql`
  ${CART_ITEMS_FIELDS}
  mutation cartCalculateDelivery($delivery: OrderDelivery!) {
    cartCalculateDelivery(delivery: $delivery) {
      delivery_price
      total_price
      items {
        ...CartItemsFields
      }
    }
  }
`

export const CART_CITY_ADDRESS = gql`
  mutation address($address: OrderDeliveryAddress!) {
    cartCityAddress(address: $address) {
      id
      title
      time_interval_delivery
      time_interval_pickup
      around_the_clock
      work_schedule_pickup {
        day
        from
        to
        type
        first_order
      }
      work_schedule_delivery {
        day
        from
        to
        type
        first_order
      }
    }
  }
`
export const ORDER_CREATE = gql`
    mutation orderCreate(
      $delivery: OrderDelivery!
      $pickup: OrderPickup
      $payment: OrderPayment!
      $comment: String!
    ) {
      orderCreate(
        delivery: $delivery
        pickup: $pickup
        payment: $payment
        comment: $comment
      ) {
        id
        order_number
        payment_url
        items {
          id
          quantity
          product {
            id
            title
            price
            image_small_url
            meta_weight
            meta_weight_unit
          }
          modifier_items {
            id
            title
            modifier_title
            price
          }
          total_price
        }
        status
        status_title
        completion_time
        created_at
        delivery_price
        total_price
      }
    }
 `;

export const PROFILE_EDIT = gql`
    mutation profileEdit(
      $city_id: ID
      $name: String
      $last_name: String
      $birthday: Date
      $gender: Gender
      $notify_via_phone: Boolean
      $notify_via_email: Boolean
      $email: String
    ) {
      profileEdit(
        city_id: $city_id
        name: $name
        last_name: $last_name
        birthday: $birthday
        gender: $gender
        notify_via_phone: $notify_via_phone
        notify_via_email: $notify_via_email
        email: $email
      ) {
        success
      }
    }
 `;
