<script setup>

import BaseButton from "@/components/base/BaseButton.vue";
import router from "@/router";
import HeaderContacts from "@/components/Header/HeaderContacts/HeaderContacts.vue";
import HeaderSearch from "@/components/Header/HeaderSearch/HeaderSearch.vue";
import {useAuthStore} from "@/store/AuthStore";
import {useDisplay} from "vuetify";
import {useProfileStore} from "@/store/ProfileStore";

const authStore = useAuthStore();
const profileStore = useProfileStore();
const {mdAndDown} = useDisplay();

const handleNavigateToCart = () => {
  router.push({
    path: "/cart",
  });
}

const handleLogout = () => {
  router.push({
    path: "/",
  });
  authStore.logout();
}

const openDrawer = () => {
  profileStore.toggleMobileDrawer();
}

</script>

<template>
  <v-sheet
    class="position-sticky top-0 border-b bg-white pa-4 container d-flex justify-space-between align-center"
  >
    <v-sheet v-if="!mdAndDown">
      <header-contacts />
    </v-sheet>
    <v-sheet
      v-if="!mdAndDown"
      min-width="200"
    >
      <header-search />
    </v-sheet>
    <v-sheet
      class="mx-auto"
      width="126"
    >
      <router-link
        to="/"
        class="d-flex d-flex align-center justify-center text-grey-darken-3 text-decoration-none"
      >
        <v-img
          width="100%"
          height="auto"
          src="@/assets/Logo_Tom_Yam.svg"
        />
      </router-link>
    </v-sheet>
    <v-sheet class="d-flex flex-row ga-2">
      <v-sheet width="150px">
        <base-button
          text="Корзина"
          @click="handleNavigateToCart"
        />
      </v-sheet>
      <v-sheet
        v-if="!mdAndDown"
        width="150px"
      >
        <base-button
          text="Выход"
          @click="handleLogout"
        />
      </v-sheet>

      <v-sheet v-if="mdAndDown">
        <v-app-bar-nav-icon @click="openDrawer" />
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.container {
  z-index: 1000;
}
</style>
