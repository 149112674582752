<script setup>
import {computed, ref, watch} from "vue";
  import EditProfileField from "@/components/Profile/EditProfile/EditProfileField.vue";
  import {useAuthStore} from "@/store/AuthStore";
  import {storeToRefs} from "pinia";
  import ProfileCustomButton from "@/components/Profile/components/ProfileCustomButton.vue";
  import {useForm} from "vee-validate";
  import * as yup from "yup";
import {useAlertStore} from "@/store/AlertStore";

  const authStore = useAuthStore();
  const alertStore = useAlertStore();
  const { user } = storeToRefs(authStore);
  const schema = yup.object({
    name: yup.string().min(4,'Имя не может быть короче 4 символов'),
    lastName: yup.string(),
    phone: yup.string().matches(/^7\d{10}$/, 'Введите номер в формате 7XXXXXXXXXX').required('Телефон обязателен'),
    birthday: yup.date().nullable(),
    email: yup.string().email('Введите корректный email')
        .test('contains-at-and-dot', 'Email должен содержать "@" и "."', (value) => {
          return value ? value.includes('@') && value.includes('.') : false;
        })
  });
  const isFormSending = ref(false);

  // Инициализация формы
  const {values, errors, validate, resetForm} = useForm({
    validationSchema: schema,
    initialValues: {
      name: user.value.name,
      lastName: user.value.last_name,
      phone: user.value.phone_number,
      birthday: user.value.birthday,
      email: user.value.email,
    },
  });

  // Переменная для отслеживания, были ли изменения
  const isModified = ref(false);

  // Валидация при изменении значений
  watch(values, async () => {
    isModified.value = true; // Отмечаем, что были изменения
    await validate(); // Проводим валидацию при каждом изменении
  });

  // Проверка, когда форма валидна и были ли изменения
  const isFormValid = computed(() => {
    return isModified.value && Object.keys(errors.value).length === 0;
  });

  // Обработка формы
  const onSubmit = async () => {
    try {
      isFormSending.value = true;
      const data = {
        name: values.name,
        last_name: values.lastName,
        birthday: values.birthday,
        email: values.email,
      }
      // Сюда добавить обработку отправки
      await authStore.editProfile(data)
      await authStore.getUserInfo();
      alertStore.openAlert('success', 'Данные сохранены')
    } finally {
      isFormSending.value = false;
    }
  };

</script>

<template>
  <v-sheet class="d-flex flex-column flex-lg-row flex-wrap ga-3 edit-profile-grid mb-4">
    <edit-profile-field
      v-model="values.name"
      :error-message="errors.name"
      title="Имя"
      name="name"
    />
    <edit-profile-field
      v-model="values.lastName"
      :error-message="errors.lastName"
      title="Фамилия"
      name="lastName"
    />
    <edit-profile-field
      v-model="values.phone"
      :error-message="errors.phone"
      title="Телефон"
      name="phone"
    />
    <edit-profile-field
      v-model="values.birthday"
      :error-message="errors.birthday"
      title="День рождения"
      name="birthday"
    />
    <edit-profile-field
      v-model="values.email"
      :error-message="errors.email"
      title="Email"
      name="email"
    />
  </v-sheet>
  <v-sheet
    class="w-100 d-flex flex-row justify-end"
  >
    <v-sheet
      style="max-width: 180px;"
      class=" w-100"
    >
      <profile-custom-button
        :on-click="onSubmit"
        :disabled="!isFormValid"
        :loading="isFormSending"
      >
        Сохранить
      </profile-custom-button>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.edit-profile-grid > * {
  width: 100%;
  box-sizing: border-box;

  @media(min-width: 1280px) {
    width: calc(50% - 10px) !important; /* делим на 2 колонки с небольшим отступом */

  }
}

.edit-profile-grid > *:last-child {
  width: 100% !important;
}

@media (max-width: 600px) {
  .edit-profile-grid > * {
    width: 100%;
  }
}
</style>
