import { ref } from 'vue';
import { defineStore } from 'pinia';
import apolloClient from "../../apolloClient";
import { VERIFICATION_CODE, AUTHORIZATION_TOKEN, LOGOUT, PROFILE_DELETE, USER } from '@/api/queries';
import Cookies from "js-cookie";
import { COOKIE_AUTH_TOKEN } from "@/constants/cookies";
import { generateRandomString, removeNonNumeric } from "@/helpers";
import { PROFILE_EDIT } from "@/api/mutations";
import {useMenuStore} from "@/store/MenuStore";

export const useAuthStore = defineStore('authStore', () => {
    // State
    const token = ref(null);
    const isAuthenticated = ref(false);
    const verificationCodeId = ref(null);
    const verificationCode = ref(null);
    const isLoading = ref(true);
    const isAuthModalOpen = ref(false);
    const user = ref(null);
    const postAuthAction = ref(null);
    // Actions

    const requestVerificationCode = async (phoneNumber) => {
        isLoading.value = true;
        const formattedPhone = removeNonNumeric(phoneNumber);
        try {
            const response = await apolloClient.mutate({
                mutation: VERIFICATION_CODE,
                variables: {
                    phone_number: formattedPhone,
                    device_name: `web_${generateRandomString(20)}`,
                },
            });
            verificationCodeId.value = response.data.verificationCode.verification_code_id;
            return response.data.verificationCode.is_new_user;
        } catch (error) {
            console.error('Ошибка при запросе кода верификации:', error);
            throw error;
        } finally {
            isLoading.value = false;
        }
    };

    const login = async (verificationCode, cityId = null, name = null, items= []) => {
        isLoading.value = true;
        try {
            const response = await apolloClient.mutate({
                mutation: AUTHORIZATION_TOKEN,
                variables: {
                    verification_id: verificationCodeId.value,
                    verification_code: verificationCode,
                    city_id: cityId,
                    name: 'userName',
                    items: items,
                },
            });

            token.value = response.data.login.token;
            isAuthenticated.value = true;
            Cookies.set(COOKIE_AUTH_TOKEN, response.data.login.token);
            await editProfile({city_id: cityId});
            await getUserInfo();

            if (postAuthAction.value) {
                await postAuthAction.value();  // Выполняем сохраненное действие
                postAuthAction.value = null; // Очищаем, чтобы не выполнять снова
            }
        } finally {
            isLoading.value = false;
        }
    };

    const getUserInfo = async () => {
        isLoading.value = true;
        const menuStore = useMenuStore()
        try {
            const response = await apolloClient.query({
                query: USER,
            });
            user.value = response.data.me;
            await menuStore.setCurrentCity(response.data.me.city)
        } catch (error) {
            console.error('Ошибка при получении информации о пользователе:', error);
            throw error;
        } finally {
            isLoading.value = false;
        }
    };

    const logout = async () => {
        isLoading.value = true;
        try {
            const response = await apolloClient.mutate({
                mutation: LOGOUT,
            });

            if (response.data.logout.success) {
                token.value = null;
                isAuthenticated.value = false;
                Cookies.remove(COOKIE_AUTH_TOKEN); // Очистить токен из cookies
            }
        } catch (error) {
            console.error('Ошибка при выходе из системы:', error);
            throw error;
        } finally {
            isLoading.value = false;
        }
    };

    const deleteProfile = async () => {
        isLoading.value = true;
        try {
            const response = await apolloClient.mutate({
                mutation: PROFILE_DELETE,
            });

            if (response.data.profileDelete.success) {
                token.value = null;
                isAuthenticated.value = false;
            }
        } catch (error) {
            console.error('Ошибка при удалении профиля:', error);
            throw error;
        } finally {
            isLoading.value = false;
        }
    };

    const checkAuth = async () => {
        try {
            const tokenFromCookie = Cookies.get(COOKIE_AUTH_TOKEN);
            if (tokenFromCookie) {
                token.value = tokenFromCookie;
                await getUserInfo();
                isAuthenticated.value = true;
                return true;
            }
            isAuthenticated.value = false;
            return false;
        } catch (error) {
            console.error(error);
        }
    };

    const getTokenFromCookie = async () => {
        const tokenFromCookie = Cookies.get(COOKIE_AUTH_TOKEN);
        if (tokenFromCookie) {
            token.value = tokenFromCookie;
            isAuthenticated.value = true;
        }
    };

    const openLoginModal = () => {
        isAuthModalOpen.value = true;
    };

    const closeLoginModal = () => {
        isAuthModalOpen.value = false;
    };

    const editProfile = async (editProfileData) => {
        try {
            await apolloClient.mutate({
                mutation: PROFILE_EDIT,
                variables: {...editProfileData},
            });
        } catch (err) {
            console.error('Ошибка редактирования профиля:', err);
        }
    };

    const setPostAuthAction = (callback) => {
        postAuthAction.value = callback;
    };

    // Return state and actions
    return {
        token,
        isAuthenticated,
        verificationCodeId,
        verificationCode,
        isLoading,
        isAuthModalOpen,
        user,
        requestVerificationCode,
        login,
        getUserInfo,
        logout,
        deleteProfile,
        checkAuth,
        getTokenFromCookie,
        openLoginModal,
        closeLoginModal,
        editProfile,
        setPostAuthAction,
    };
});
