import { parse, isEqual } from 'date-fns';

export const log = (value) => {
    console.log(JSON.parse(JSON.stringify(value)));
}

export const isDatesEqual = (date1, format1, date2, format2) => {
    // Преобразуем первую дату с использованием первого формата
    const parsedDate1 = parse(date1, format1, new Date());

    // Преобразуем вторую дату с использованием второго формата
    const parsedDate2 = parse(date2, format2, new Date());

    // Сравниваем даты с использованием isEqual
    return isEqual(parsedDate1, parsedDate2);
}

export const combineDateTime = (timeString, dateObject) => {
    if (!timeString || !dateObject) return null;
    // Разбиваем время на часы и минуты
    const [hours, minutes] = timeString.split(':').map(Number);

    // Устанавливаем часы и минуты в объекте даты
    dateObject.setHours(hours);
    dateObject.setMinutes(minutes);
    dateObject.setSeconds(0);  // Секунды обнуляем

    // Преобразуем в формат ISO 8601 (UTC)
    return dateObject.toISOString();
}

export const generateRandomString = (length) =>  {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export const removeNonNumeric = (str) => {
    return str.replace(/\D/g, ''); // \D соответствует всем символам, кроме цифр
}

export const cleanLink = (link) =>  {
    return link.replace(/^(tel:|mailto:)/, "");
}

export const formatPhoneNumber = (phone) =>  {
    // Убедимся, что номер содержит только цифры, удалив все лишние символы
    const cleanedPhone = phone.replace(/[^\d]/g, "");

    // Форматируем номер, добавляя +7, скобки и дефисы
    return cleanedPhone.replace(
        /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/,
        "+$1 ($2) $3-$4-$5"
    );
}

export const formatDate = (dateString)=> {
    const date = new Date(dateString);

    const formatter = new Intl.DateTimeFormat('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    return formatter.format(date);
}
