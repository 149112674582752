<script setup>
import BaseTitle from "@/components/base/BaseTitle/BaseTitle.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import {computed, nextTick, onMounted, ref, watch} from "vue";
import { useMenuStore } from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import {Virtual} from "swiper/modules";
import {useCartStore} from "@/store/CartStore";
import {useDisplay} from "vuetify";

// Подключение к store
const menuStore = useMenuStore();
const cartStore = useCartStore();
const { sections } = storeToRefs(menuStore);
const { items } = storeToRefs(cartStore);

// Вычисление продуктов
const products = computed(() => {
  return sections.value.length > 0 ? sections.value.find((section) => section.title === 'Добавки').products : [];
});
const { xs } = useDisplay();

// Ссылка на Swiper
const mySwiper = ref(null);

// Функция для инициализации Swiper
const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

// Функции для прокрутки
const slideNext = () => {
  if (mySwiper.value && mySwiper.value.initialized) {
    mySwiper.value.slideNext();
  }
};

const slidePrev = () => {
  if (mySwiper.value && mySwiper.value.initialized) {
    mySwiper.value.slidePrev();
  }
};

const handleAddToCart = async (product) => {
  const productFromCart = items.value.find((item) => {
    return item.product.id === product.id;
  });
  if (productFromCart) {
    await cartStore.cartItemUpdate(productFromCart.id, productFromCart.quantity + 1);
  } else {
    await cartStore.addToCart(product);
  }
}

// Внимательно следим за изменением продуктов и обновляем Swiper
watch(products, async () => {
  if (products.value.length > 0) {
    await nextTick(); // Ожидаем обновления DOM
    if (mySwiper.value) {
      mySwiper.value.update(); // Обновляем Swiper после изменений
    }
  }
});

// Используем onMounted для загрузки после инициализации компонента
onMounted(() => {
  if (mySwiper.value) {
    mySwiper.value.update();
  }
});
</script>


<template>
  <v-sheet class="w-100 rounded-xl">
    <v-sheet class="w-100">
      <base-title class="mb-4">
        Добавьте к заказу
      </base-title>
    </v-sheet>
    <v-sheet class=" w-100">
      <v-sheet class="position-relative w-100 h-100">
        <swiper
          v-if="products.length > 0"
          ref="mySwiper"
          class="pr-4"
          :slides-per-view="xs ? 1 : 2"
          :space-between="20"
          :loop="true"
          :virtual="true"
          :modules="[Virtual]"
          @swiper="onSwiper"
        >
          <swiper-slide
            v-for="(product, i) in products"
            :key="i"
            :virtual-index="i"
          >
            <v-sheet
              class="pa-1 h-100"
              min-height="100%"
            >
              <v-sheet
                color="transparent"
                elevation="3"
                class="rounded-xl d-flex flex-column align-center w-100 pa-2 h-100"
              >
                <v-img
                  width="100px"
                  height="144px"
                  :src="product.image_full_url"
                />
                <v-sheet class="text-subtitle-2 semibold text-center px-2">
                  {{ product.title }}
                </v-sheet>
                <v-sheet
                  color="transparent"
                  class="text-subtitle-1 justify-space-between d-flex flex-row align-center w-100"
                >
                  <v-sheet
                    color="transparent"
                    class="px-4 semibold"
                  >
                    {{ product.meta_weight }} г.
                  </v-sheet>
                  <v-sheet
                    color="transparent"
                    class="px-4 font-weight-bold text-h6"
                  >
                    {{ product.price }} ₽
                  </v-sheet>
                </v-sheet>
                <v-sheet
                  color="transparent"
                  class="px-4 my-4 w-100 d-flex align-center justify-center buttonContainer"
                >
                  <v-btn
                    class="rounded-xl text-uppercase"
                    color="#e31d24"
                    @click="() => handleAddToCart(product)"
                  >
                    Добавить
                  </v-btn>
                </v-sheet>
              </v-sheet>
            </v-sheet>
          </swiper-slide>
        </swiper>
        <v-container class="custom-navigation">
          <v-btn
            class="custom-button prev-button text-customRed border elevation-0"
            variant="elevated"
            density="compact"
            icon="mdi-chevron-left"
            size="large"
            color="white"
            @click="slidePrev"
          />
          <v-btn
            class="custom-button prev-button text-customRed border elevation-0"
            variant="elevated"
            density="compact"
            icon="mdi-chevron-right"
            size="large"
            color="white"
            @click="slideNext"
          />
        </v-container>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.custom-button {
  pointer-events: all;
}

.custom-navigation {
  position: absolute;
  top: 0;
  left: -22px;
  z-index: 10;
  width: calc(100% + 44px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  padding: 0;
}

.buttonContainer {
  div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
