<template>
  <v-skeleton-loader
    :loading="isLoading"
    type="image"
  >
    <v-row class="mb-12 mx-0">
      <v-card-title
        :style="{fontSize: smAndDown ? '24px' : '40px'}"
        class="regular text-uppercase pa-0"
      >
        {{ props.section.title }}
      </v-card-title>
      <v-divider
        class="border-opacity-100 my-4"
        color="#aaa"
      />
      <v-sheet class="pa-0 ma-0 d-flex flex-row flex-wrap">
        <v-sheet
          v-for="product in itemsToShow"
          :key="product.id"
          cols="12"
          class="pa-0 ma-0 w-100 w-md-50"
        >
          <MenuItem :product="product" />
        </v-sheet>
      </v-sheet>
    </v-row>
  </v-skeleton-loader>
</template>

<script setup>
import MenuItem from "@/components/Menu/MenuItem/MenuItem.vue";
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import {computed} from "vue";
import {useDisplay} from "vuetify";
const menuStoreData = useMenuStore();
const isLoading = storeToRefs(menuStoreData).isLoading;
const { smAndDown } = useDisplay();

const itemsToShow = computed(() => props.section.products
    .filter((product) => product.price > 0)
    .filter((product) => product.active)
    .sort((a, b) => a.priority - b.priority)
)

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
})
</script>
