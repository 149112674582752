<template>
  <v-btn
    flat
    size="x-small"
    @click.stop="menu = !menu"
  >
    <v-sheet class="bg-transparent mr-1 semibold">
      МЕНЮ
    </v-sheet>
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
  <v-navigation-drawer
    v-model="menu"
    theme="white"
  >
    <template #prepend>
      <v-sheet class="d-flex justify-center align-center pt-5 semibold text-grey-lighten-1">
        МЕНЮ
      </v-sheet>
    </template>
    <v-list nav>
      <v-list-item
        v-for="(item, index) in menuStoreData.sectionsToShow"
        :key="index"
        @click="handleItemClick(item)"
      >
        <v-list-item-title class="mb-3">
          {{ item.title }}
        </v-list-item-title>
        <v-divider />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import { ref} from 'vue';
import { useRouter } from 'vue-router';
import {useMenuStore} from "@/store/MenuStore";

const isMenuOpen = ref(false); // Управление состоянием меню
const icon = ref("mdi-menu"); // Иконка для кнопки, можно заменить
const menuStoreData = useMenuStore();
const router = useRouter();
const menu = ref(false)

// Обработчик нажатия на элемент меню
const handleItemClick = (item) => {
  router.push({
    path: "/",
    hash: `#${item.id}`,
  });
  isMenuOpen.value = true; // Закрываем меню после выбора
};
</script>

<style scoped>
.v-list-item-title {
  cursor: pointer;
}
</style>
