<script setup>
import BaseAlert from "@/components/base/BaseAlert.vue";
import {onMounted} from "vue";
import {useAuthStore} from "@/store/AuthStore";
import {storeToRefs} from "pinia";
import LoginModal from "@/components/Auth/LoginModal.vue";
const authStore = useAuthStore();
const {isAuthenticated} = storeToRefs(authStore);

onMounted(async () => {
  try {
    // await authStore.checkAuth();
    // if (!isAuthenticated.value) {
    //   authStore.openLoginModal()
    // }
  } catch (error) {
    console.error(error);
  }
});
</script>

<template>
  <v-app>
    <v-main class="pa-0">
      <router-view />
    </v-main>
    <BaseAlert />
    <LoginModal />
  </v-app>
</template>

<style lang="scss">
@import "@/assets/fonts/font.css";

* {
  font-family: "Proxima Nova", sans-serif !important;
}
.semibold {
  font-family: "Proxima Nova semibold", sans-serif !important;
}

.bold {
  font-family: "Proxima Nova bold", sans-serif !important;
}

.regular {
  font-family: "Proxima Nova regular" !important;
}

.fullscreen-skeleton {
  width: 100vh;
  min-height: 100vh;
  height: 100vh !important;
  div {
    height: 100vh !important;
    background: rgba(0, 0, 0, 0.3) !important;
  }
}

.swiper-pagination-bullet-active {
  background: red!important;
}

body {
  overflow-x: hidden;
}
</style>
