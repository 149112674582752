<script setup>

</script>

<template>
  <v-dialog
    max-width="none"
    fullscreen
    close-on-content-click
  >
    <template #activator="{ props: activatorProps }">
      <v-sheet
        v-bind="activatorProps"
        class="text-white text-decoration-underline pa-0 ma-0 bg-transparent cursor-pointer"
      >
        Процесс оплаты
      </v-sheet>
    </template>

    <template #default="{ isActive }">
      <v-card>
        <template #append>
          <v-icon
            class="cursor-pointer"
            color="black"
            icon="mdi-close"
            @click="isActive.value = false"
          />
        </template>
        <v-card-text>
          <v-sheet
            class="d-flex w-100 h-100 justify-center align-center flex-column mx-auto ga-5"
            max-width="1400"
          >
            <v-sheet class="w-100">
              Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз сервиса ЮMoney.
            </v-sheet>
            <v-sheet class="w-100">
              Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa или MasterCard SecureCode для проведения платежа также может потребоваться ввод специального пароля. Настоящий сайт поддерживает 256-битное шифрование.
            </v-sheet>
            <v-sheet class="w-100">
              Конфиденциальность сообщаемой персональной информации обеспечивается сервисом ЮMoney. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем
            </v-sheet>
          </v-sheet>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<style scoped>

</style>
