<script setup>
import {ref} from "vue";
import BaseButton from "@/components/base/BaseButton.vue";
import {useDisplay} from "vuetify";
const { xs } = useDisplay();

const isOpen = ref(true);
const handleCLiskButton = () => {
  isOpen.value = false;
}

</script>

<template>
  <v-dialog
    :close-on-back="true"
    :model-value="isOpen"
    persistent
    width="100%"
    :max-width="xs ? 'none' : 780"
    :fullscreen="xs"
  >
    <v-sheet class="w-100 pa-6 rounded-xl d-flex justify-center align-center flex-column">
      <v-row>
        <v-col
          md="2"
          cols="12"
          class="d-flex justify-center"
          :class="xs ? 'align-center' : 'align-start'"
        >
          <v-sheet
            style="width: 90px; height: 90px"
            border="customRed md"
            class="rounded-circle bg-transparent pa-2 border-solid border-opacity-100"
          >
            <v-img
              class=""
              width="100%"
              src="@/assets/warn.svg"
            />
          </v-sheet>
        </v-col>
        <v-col
          md="10"
          cols="12"
        >
          <v-sheet class="pl-2 d-flex flex-column ga-4 justify-center align-center flex-column w-100">
            <v-sheet
              class="text-subtitle-1 font-weight-regular text-w lh-1 text-pre-wrap w-100"
              :class="xs && 'text-center'"
            >
              Обращаем Ваше внимание, что в наших блюдах содержатся продукты
              <span class="text-customRed">(мидии в створках, креветки в панцире, оригинальные специи и др.)
                и аллергены,</span>
              требующие особого внимания для Вашей безопасности. Будьте осторожны!
            </v-sheet>
            <v-sheet
              class="bg-transparent mt-5 w-100"
              max-width="400"
            >
              <v-img
                class=""
                width="100%"
                height="auto"
                src="@/assets/perec.svg"
              />
            </v-sheet>
          </v-sheet>
        </v-col>
      </v-row>
      <v-sheet
        class="w-100 mt-8"
        max-width="250"
      >
        <base-button
          text="ОК"
          @click="handleCLiskButton"
        />
      </v-sheet>
    </v-sheet>
  </v-dialog>
</template>

<style scoped>

</style>
