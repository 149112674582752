<template>
  <v-sheet
    v-if="!!currentCity"
    elevation="4"
    :height="smAndDown ? xs ? 150 : 300 : 500"
    rounded="xl"
    class="position-relative mx-auto"
    width="100%"
    max-width="1220"
  >
    <v-sheet
      rounded="xl"
      class="pa-0 fill-height overflow-hidden"
    >
      <swiper
        ref="mySwiper"
        :modules="[Pagination]"
        :slides-per-view="1"
        :space-between="0"
        style="height: 100%"
        :pagination="{
          clickable: true,
          el: '.swiper-pagination',
          type: 'bullets'
        }"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(slide, i) in currentCity.banners"
          :key="i"
          style="height: 100%"
        >
          <v-sheet class="fill-height pa-0">
            <v-img
              cover
              height="100%"
              :src="slide.url"
            />
          </v-sheet>
        </swiper-slide>
      </swiper>
    </v-sheet>
    <v-container class="custom-navigation">
      <v-btn
        class="custom-button prev-button text-customRed"
        variant="elevated"
        density="compact"
        icon="mdi-chevron-left"
        size="x-large"
        color="white"
        @click="slidePrev"
      />
      <v-btn
        class="custom-button prev-button text-customRed"
        variant="elevated"
        density="compact"
        icon="mdi-chevron-right"
        size="x-large"
        color="white"
        @click="slideNext"
      />
    </v-container>
  </v-sheet>
  <v-sheet class="swiper-pagination w-100 pagination bg-transparent mt-3" />
</template>
<script setup>
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";
import {storeToRefs} from "pinia";
import {useMenuStore} from "@/store/MenuStore";
import {useDisplay} from "vuetify";
import "swiper/css/pagination";
import {Pagination} from "swiper/modules";

const mySwiper = ref(null);
const {currentCity} = storeToRefs(useMenuStore())
const { smAndDown, xs } = useDisplay();

const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

const slideNext = () => {
  if (mySwiper.value) {
    mySwiper.value.slideNext();
  }
};

const slidePrev = () => {
  if (mySwiper.value) {
    mySwiper.value.slidePrev();
  }
};
</script>

<style scoped>
.custom-button {
  pointer-events: all;
}

.custom-navigation {
  position: absolute;
  top: 0;
  left: -22px;
  z-index: 10;
  width: calc(100% + 44px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  padding: 0;
  margin: 0 auto;
  min-width: calc(100% + 44px);

  @media (max-width: 1300px) {
    min-width: 100%;
    width: 100%;
    left: 0;
  }


  @media (max-width: 960px) {
    display: none;
  }
}

.pagination {
  position: relative;
}
</style>
