<template>
  <div
    ref="scrollContainer"
    class="container"
  >
    <SectionItem
      v-for="item in menuStoreData.sectionsToShow"
      :id="item.id"
      :key="item.id"
      ref="contentBlocks"
      class="section"
      :section="item"
      :title="item.title"
    />
  </div>
  <SelectIngredientsModal />
</template>

<script setup>
import { useMenuStore } from "@/store/MenuStore";
import { ref, onMounted } from "vue";
import SectionItem from "@/components/Menu/SectionItem/SectionItem.vue";
import SelectIngredientsModal from "@/components/modals/SelectIngredientsModal.vue";

const menuStoreData = useMenuStore();
const contentBlocks = ref([]);

onMounted(() => {
  const sections = document.querySelectorAll(".section");
  const options = {
    threshold: 0.1,
  };
  const observer = new IntersectionObserver(function (entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        menuStoreData.changeActiveSection(entry.target.id);
      }
    });
  }, options);
  sections.forEach((section) => {
    observer.observe(section);
  });
});
</script>

<style scoped></style>
