import {ApolloClient, InMemoryCache, createHttpLink, from} from '@apollo/client/core';
import {appConfig} from "./config";
import {setContext} from "@apollo/client/link/context";
import {useAuthStore} from "@/store/AuthStore";
import {onError} from "@apollo/client/link/error";
import {useAlertStore} from "@/store/AlertStore";
import {getActivePinia} from "pinia";

const httpLink = createHttpLink({
    uri: `${appConfig.API_URL}/graphql`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    const alertStore = getActivePinia() ? useAlertStore() : null;

    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            if (alertStore) {
                alertStore.openAlert('error', message);
            }
        });
    }

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
        if (alertStore) {
            // Проверка, если причина сетевой ошибки - отсутствие соединения
            if (networkError.message === 'NetworkError when attempting to fetch resource.') {
                alertStore.openAlert('error', 'Потеряно соединение с интернетом.');
            } else {
                alertStore.openAlert('error', networkError.message);
            }
        }
    }
});

const authHeader = setContext(() => {
    // Используйте Pinia для получения токена
    const authStore = useAuthStore();
    const token = authStore.token; // Предполагается, что в хранилище есть поле 'token'

    return {
        headers: {
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    link: from([authHeader, errorLink, httpLink]),
    cache,
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            notifyOnNetworkStatusChange: true,
        },
    },

});

export default apolloClient;
